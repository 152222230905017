import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Table,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Card,
  CardBody,
} from 'reactstrap';
// import { FaEllipsisV } from 'react-icons/fa';
import { fetchAttendance } from '../../store/attendanceSlice';

const AttendanceList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Fetch attendance from the Redux store
  const { attendanceList, loading } = useSelector((state) => state.attendance);

  useEffect(() => {
    dispatch(fetchAttendance({ staffId: id }));
  }, [dispatch, id]);

  return (
    <Card>
      <CardBody>
        <h5 className="mb-4">Attendance Records</h5>
        <Table className="align-middle">
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Staff ID</th>
              <th>Status</th>
              <th>Date & Time</th>
              <th>Comment</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : attendanceList.length > 0 ? (
              attendanceList.map((attendance, index) => (
                <tr key={attendance.id}>
                  <td>{index + 1}</td>
                  <td>{attendance.name}</td>
                  <td>{attendance.staffId}</td>
                  <td>{attendance.status}</td>
                  <td>{new Date(attendance.dateTime).toLocaleString()}</td>
                  <td>{attendance.comment}</td>
                  {/* <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="div">
                        <div style={{ cursor: 'pointer' }}>
                          <FaEllipsisV />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Edit</DropdownItem>
                        <DropdownItem>Delete</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No attendance records found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default AttendanceList;
