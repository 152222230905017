import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import AllPatient from '../views/skchreception/AllPatient';
import EditPatient from '../views/skchreception/EditPatient ';
import PatientInvoice from '../views/skchreception/PatientInvoice';
import EditInvoice from '../views/skchreception/EditInvoice';
import AllAchievements from '../views/skchmanagement/AllAchievements';
import TaskList from '../views/skchmanagement/TaskList';
import AttendanceList from '../views/skchmanagement/AttendanceList';
import HospitalExpenseList from '../views/skchmanagement/HospitalExpenseList';
import HospitalIncomeList from '../views/skchmanagement/HospitalIncomeList';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const AddStaff = Loadable(lazy(() => import('../views/skchmanagement/AddStaff')));
const AllStaff = Loadable(lazy(() => import('../views/skchmanagement/AllStaff')));
const StaffEdit = Loadable(lazy(() => import('../views/skchmanagement/StaffEdit')));
const HospitalInput = Loadable(lazy(() => import('../views/skchmanagement/HospitalInput')));
const AddPatient = Loadable(lazy(() => import('../views/skchreception/AddPatient')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboards/minimal" /> },
      { path: '/dashboards/minimal', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/skch-management/add-staff', name: 'Add Staff', exact: true, element: <AddStaff /> },
      { path: '/skch-management/all-staff', name: 'All Staff', exact: true, element: <AllStaff /> },
      {
        path: '/skch-management/all-achievements/:id',
        name: 'All Achievements',
        exact: true,
        element: <AllAchievements />,
      },
      {
        path: '/skch-management/task-list/:id',
        name: 'Task List',
        exact: true,
        element: <TaskList />,
      },
      {
        path: '/skch-management/attendance-list/:id',
        name: 'Attendance List',
        exact: true,
        element: <AttendanceList />,
      },
      {
        path: '/skch-management/hospital-expenseList',
        name: 'Hospital ExpenseList',
        exact: true,
        element: <HospitalExpenseList />,
      },
      {
        path: '/skch-management/hospital-incomeList',
        name: 'Hospital IncomeList',
        exact: true,
        element: <HospitalIncomeList />,
      },
      {
        path: '/skch-management/staff-edit/:id',
        name: 'Staff Edit',
        exact: true,
        element: <StaffEdit />,
      },
      {
        path: '/skch-management/hospital-input',
        name: 'Hospital Input',
        exact: true,
        element: <HospitalInput />,
      },
      {
        path: '/skch-reception/add-patient',
        name: 'Add Patient',
        exact: true,
        element: <AddPatient />,
      },
      {
        path: '/skch-reception/all-patient',
        name: 'Add Patient',
        exact: true,
        element: <AllPatient />,
      },
      {
        path: '/skch-reception/patient-edit/:id',
        name: 'Add Patient',
        exact: true,
        element: <EditPatient />,
      },
      {
        path: '/skch-reception/patient-invoice/:id',
        name: 'Patient Invoice',
        exact: true,
        element: <PatientInvoice />,
      },
      {
        path: '/skch-reception/edit-invoice/:id',
        name: 'Edit Invoice',
        exact: true,
        element: <EditInvoice />,
      },
      // { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'loginformik', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default ThemeRoutes;
