import { configureStore } from '@reduxjs/toolkit';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';
import staffSlice from './staffSlice';
import patientSlice from './patientSlice';
import achievementSlice from './achievementSlice';
import taskSlice from './taskSlice';
import attendanceSlice from './attendanceSlice';
import hospitalIncomeSlice from './hospitalIncomeSlice';
import hospitalExpenseSlice from './hospitalExpenseSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    chatReducer: ChatsReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    ticketReducer: TicketReducer,
    staff: staffSlice,
    patient: patientSlice,
    achievement: achievementSlice,
    task: taskSlice,
    attendance: attendanceSlice,
    hospitalIncome: hospitalIncomeSlice,
    hospitalExpense: hospitalExpenseSlice,
  },
});

export default store;
