import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_HOSPITAL_EXPENSE } from '../Utils/API';

// Async thunk for fetching hospital expenses
export const fetchHospitalExpense = createAsyncThunk(
  'hospitalExpense/fetchHospitalExpense',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(BASE_URL + FETCH_HOSPITAL_EXPENSE);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching hospital expenses');
    }
  },
);

const hospitalExpenseSlice = createSlice({
  name: 'hospitalExpense',
  initialState: {
    expenseList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHospitalExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseList = action.payload;
      })
      .addCase(fetchHospitalExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch hospital expense data';
      });
  },
});

export default hospitalExpenseSlice.reducer;
