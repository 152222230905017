import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_HOSPITAL_INCOME } from '../Utils/API';

// Async thunk for fetching hospital income
export const fetchHospitalIncome = createAsyncThunk(
  'hospitalIncome/fetchHospitalIncome',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(BASE_URL + FETCH_HOSPITAL_INCOME);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching hospital income');
    }
  },
);

const hospitalIncomeSlice = createSlice({
  name: 'hospitalIncome',
  initialState: {
    incomeList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalIncome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHospitalIncome.fulfilled, (state, action) => {
        state.loading = false;
        state.incomeList = action.payload;
      })
      .addCase(fetchHospitalIncome.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch hospital income data';
      });
  },
});

export default hospitalIncomeSlice.reducer;
