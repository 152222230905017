import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Table,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Card,
  CardBody,
} from 'reactstrap';
// import { FaEllipsisV } from 'react-icons/fa';
import { fetchAchievements } from '../../store/achievementSlice';

const AllAchievements = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  // Fetch achievements from the store
  const { achievementList, loading } = useSelector((state) => state.achievement);

  useEffect(() => {
    dispatch(fetchAchievements({ staffId: id }));
  }, [dispatch]);

  return (
    <Card>
      <CardBody>
        <div>
          <Table className="align-middle">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Staff ID</th>
                <th>Date</th>
                <th>Comment</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : achievementList.length > 0 ? (
                achievementList.map((achievement, index) => (
                  <tr key={achievement.id}>
                    <td>{index + 1}</td>
                    <td>{achievement.name}</td>
                    <td>{achievement.staffId}</td>
                    <td>{achievement.date}</td>
                    <td>{achievement.comment}</td>
                    {/* <td>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="div">
                          <div style={{ cursor: 'pointer' }}>
                            <FaEllipsisV />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Edit</DropdownItem>
                          <DropdownItem>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No achievements found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AllAchievements;
