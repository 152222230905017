import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_ACHIEVEMENTS } from '../Utils/API';

// Async thunk for fetching achievement data
export const fetchAchievements = createAsyncThunk(
  'achievement/fetchAchievements',
  async ({ staffId }, thunkAPI) => {
    try {
      const response = await axios.post(BASE_URL + FETCH_ACHIEVEMENTS, { staffId });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch achievement data');
    }
  },
);

const achievementSlice = createSlice({
  name: 'achievement',
  initialState: {
    achievementList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add non-async reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAchievements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAchievements.fulfilled, (state, action) => {
        state.loading = false;
        state.achievementList = action.payload;
      })
      .addCase(fetchAchievements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch achievement data';
      });
  },
});

export default achievementSlice.reducer;
