import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Table,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Card,
  CardBody,
} from 'reactstrap';
import {
  //  FaEllipsisV,
  FaDownload,
} from 'react-icons/fa';
import { fetchTasks } from '../../store/taskSlice';

const TaskList = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Extract staffId from the URL parameters

  const { taskList, loading, error } = useSelector((state) => state.task);

  // Fetch tasks when the component is mounted
  useEffect(() => {
    if (id) {
      dispatch(fetchTasks({ staffId: id }));
    }
  }, [dispatch, id]);

  return (
    <Card>
      <CardBody>
        <h4 className="mb-4">Tasks</h4>
        <Table className="align-middle">
          <thead>
            <tr>
              <th>No</th>
              <th>Staff ID</th>
              <th>Date</th>
              <th>Comment</th>
              <th>File</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="6" className="text-center text-danger">
                  Error: {error}
                </td>
              </tr>
            ) : taskList.length > 0 ? (
              taskList.map((task, index) => (
                <tr key={task.id}>
                  <td>{index + 1}</td>
                  <td>{task.staffId}</td>
                  <td>{new Date(task.date).toLocaleString()}</td>
                  <td>{task.comment}</td>
                  <td>
                    {task.file ? (
                      <a href={task.file} target="_blank" rel="noopener noreferrer">
                        <FaDownload style={{ cursor: 'pointer' }} />
                      </a>
                    ) : (
                      'No File'
                    )}
                  </td>
                  {/* <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="div">
                        <div style={{ cursor: 'pointer' }}>
                          <FaEllipsisV />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Edit</DropdownItem>
                        <DropdownItem>Delete</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No tasks found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default TaskList;
