import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_ATTENDANCE } from '../Utils/API';

// Async thunk for fetching attendance
export const fetchAttendance = createAsyncThunk(
  'attendance/fetchAttendance',
  async ({ staffId }, thunkAPI) => {
    try {
      const response = await axios.post(BASE_URL + FETCH_ATTENDANCE, { staffId });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching attendance');
    }
  },
);

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: {
    attendanceList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.attendanceList = action.payload;
      })
      .addCase(fetchAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch attendance data';
      });
  },
});

export default attendanceSlice.reducer;
