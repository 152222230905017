import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_TASK } from '../Utils/API';

// Async thunk for fetching tasks
export const fetchTasks = createAsyncThunk('task/fetchTasks', async ({ staffId }, thunkAPI) => {
  try {
    const response = await axios.post(BASE_URL + FETCH_TASK, { staffId });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch task data');
  }
});

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add non-async reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.taskList = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch task data';
      });
  },
});

export default taskSlice.reducer;
