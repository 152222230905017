import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Card,
  CardBody,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
// import { FaEllipsisV } from 'react-icons/fa';
import { fetchHospitalIncome } from '../../store/hospitalIncomeSlice';

const HospitalIncomeList = () => {
  const dispatch = useDispatch();
  const { incomeList, loading, error } = useSelector((state) => state.hospitalIncome);

  useEffect(() => {
    dispatch(fetchHospitalIncome());
  }, [dispatch]);

  return (
    <Card>
      <CardBody>
        <h5 className="mb-4">Hospital Income List</h5>
        <Table className="align-middle">
          <thead>
            <tr>
              <th>No</th>
              <th>Selected Option</th>
              <th>Sub Option</th>
              <th>Amount</th>
              <th>Billing Date</th>
              <th>Comment</th>
              <th>Uploaded Photo</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="8" className="text-center text-danger">
                  {error}
                </td>
              </tr>
            ) : incomeList.length > 0 ? (
              incomeList.map((income, index) => (
                <tr key={income.id}>
                  <td>{index + 1}</td>
                  <td>{income.selectedOption}</td>
                  <td>{income.subOption}</td>
                  <td>{income.amount}</td>
                  <td>{income.billingDate}</td>
                  <td>{income.comment}</td>
                  <td>
                    <a href={income.uploadPhoto} target="_blank" rel="noopener noreferrer">
                      View Photo
                    </a>
                  </td>
                  {/* <td>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="div">
                        <div style={{ cursor: 'pointer' }}>
                          <FaEllipsisV />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Edit</DropdownItem>
                        <DropdownItem>Delete</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No income records found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default HospitalIncomeList;
