import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Card,
  CardBody,
  //   UncontrolledDropdown,
  //   DropdownToggle,
  //   DropdownMenu,
  //   DropdownItem,
} from 'reactstrap';
// import { FaEllipsisV } from 'react-icons/fa';
import { fetchHospitalExpense } from '../../store/hospitalExpenseSlice';

const HospitalExpenseList = () => {
  const dispatch = useDispatch();

  // Select data from Redux store
  const { expenseList, loading, error } = useSelector((state) => state.hospitalExpense);

  // Fetch expenses on component mount
  useEffect(() => {
    dispatch(fetchHospitalExpense());
  }, [dispatch]);

  return (
    <Card>
      <CardBody>
        <h4>Hospital Expenses</h4>
        <div>
          <Table className="align-middle">
            <thead>
              <tr>
                <th>No</th>
                <th>Expense Type</th>
                <th>Amount</th>
                <th>Billing Date</th>
                <th>Comment</th>
                <th>Upload Photo</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="7" className="text-center text-danger">
                    {error}
                  </td>
                </tr>
              ) : expenseList.length > 0 ? (
                expenseList.map((expense, index) => (
                  <tr key={expense.id}>
                    <td>{index + 1}</td>
                    <td>{expense.expenseType}</td>
                    <td>{expense.amount}</td>
                    <td>{expense.billingDate}</td>
                    <td>{expense.comment}</td>
                    <td>
                      <a href={expense.uploadPhoto} target="_blank" rel="noopener noreferrer">
                        View Photo
                      </a>
                    </td>
                    {/* <td>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="div">
                          <div style={{ cursor: 'pointer' }}>
                            <FaEllipsisV />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Edit</DropdownItem>
                          <DropdownItem>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No expenses found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default HospitalExpenseList;
