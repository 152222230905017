import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaEllipsisV, FaDownload } from 'react-icons/fa';
import { fetchPatient } from '../../../store/patientSlice';

const AllPatientList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = (patient) => {
    navigate(`/skch-reception/patient-edit/${patient.id}`, { state: { patient } });
  };

  const handleDelete = async (patient) => {
    const payload = { patientId: patient.id };

    try {
      const response = await axios.post(
        'https://pharmacyapi.hybridtech.in/api/patient/deletePatient.php',
        payload,
      );

      if (response.data.statusCode === 1) {
        alert(response.data.message); // Show success message
        dispatch(fetchPatient());
      } else {
        alert('Failed to delete the patient record. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting patient:', error);
      alert('An error occurred while deleting the patient. Please try again.');
    }
  };

  const handleCreateInvoice = (patient) => {
    navigate(`/skch-reception/patient-invoice/${patient.id}`);
  };

  // const handleViewDetails = (patient) => {
  //   navigate(`/skch-management/patient-details/${patient.id}`);
  // };

  const { patientList } = useSelector((state) => state.patient);

  useEffect(() => {
    dispatch(fetchPatient());
  }, [dispatch]);

  const handleDownload = async (invoiceId) => {
    try {
      const response = await axios.get(
        `https://pharmacyapi.hybridtech.in/api/invoice/downloadInvoice.php?id=${invoiceId}`,
      );

      if (response.data.message === 'PDF generated successfully.' && response.data.link) {
        // Redirect to the PDF link
        window.open(response.data.link, '_blank');
      } else {
        alert('Failed to generate the invoice. Please try again.');
      }
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      alert('An error occurred while downloading the invoice. Please try again.');
    }
  };

  return (
    <div>
      <Table className="align-middle">
        <thead>
          <tr>
            <th>No</th>
            <th>Patient Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Doctor Name</th>
            <th>Department</th>
            <th>Download Invoice</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {patientList.map((patient, index) => (
            <tr key={patient.id}>
              <td>{index + 1}</td>
              <td>{patient.patientName}</td>
              <td>{patient.age}</td>
              <td>{patient.gender}</td>
              <td>{patient.address}</td>
              <td>{patient.doctorName}</td>
              <td>{patient.departmentOfTreatment}</td>

              <td>
                {patient?.invoice === 'true' ? (
                  <FaDownload
                    onClick={() => handleDownload(patient.id)}
                    style={{ cursor: 'pointer', color: '#fb9678' }}
                  />
                ) : (
                  'No File'
                )}
              </td>
              <td className="d-flex align-items-center gap-4">
                <UncontrolledDropdown>
                  <DropdownToggle tag="div">
                    <div style={{ cursor: 'pointer' }}>
                      <FaEllipsisV />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleEdit(patient)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => handleDelete(patient)}>Delete</DropdownItem>
                    <DropdownItem onClick={() => handleCreateInvoice(patient)}>
                      Create Invoice
                    </DropdownItem>
                    {/* <DropdownItem onClick={() => handleViewDetails(patient)}>
                      View Details
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllPatientList;
